import styles from './styles.module.scss';

export default function VideoContent({ data }) {

    return (
        <div className={styles.videoContent} style={{ '--target-color': data?.theme || '#F3D682' }}>
            <div className={`container ${styles.wrap}`}>
                <h1>{data?.title}</h1>
                {data?.subTitle && <div className={styles.subTitle}>
                    {data?.subTitle}
                </div>}

                <div className={styles.cnt}>
                    <div className={styles.video}>
                        <video  controls poster={data?.poster.url} playsInline preload="true">
                            <source src={data?.videoUrl.url} type="video/mp4" />
                        </video>
                    </div>
                    <div className={styles.contnet} dangerouslySetInnerHTML={{ __html: data?.description, }} />
                </div>
            </div>
        </div>
    );
}

