import styles from './styles.module.scss';
import Link from 'next/link';

export default function GalleryContent({ data }) {

    return (
        <div className={styles.galleryContent} style={{ '--target-color': data?.theme || '#F3D682' }}>
            <button className="backpageBtn"></button>
            <div className={`container ${styles.wrap}`}>
                <h1>{data?.title}</h1>
                {data?.subTitle && <div className={styles.subTitle}>
                    {data?.subTitle}
                </div>}
                <div className={styles.contnet} dangerouslySetInnerHTML={{ __html: data?.description, }} />
                <div className={styles.list}>
                    {data?.list?.map((list, index) => (
                        <li key={index}>
                            <Link href={`/${list.url.replace(/^\/+/, '')}`}>
                                <img src={list?.image?.url} alt={list?.image?.alternativeText} />
                            </Link>
                        </li>
                    ))}
                </div>
            </div>
        </div>
    );
}

