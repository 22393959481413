import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import PageLanding from "@/components/pageLanding";
import ImageContent from "@/components/imageContent";
import ContentAccordion from "@/components/contentAccordion";
import TitleContent from "@/components/titleContent";
import VideoContent from "@/components/videoContent";
import GalleryContent from "@/components/galleryContent";
import secureLocalStorage from "react-secure-storage";
import Link from 'next/link';
import Back from '../public/icons/icon-left-arrow.svg';

export default function DynamicPage() {
  const router = useRouter();
  const { slug } = router.query;
  const [pageData, setPageData] = useState(null);
  const [loading, setLoading] = useState(true);

  // const slugMapping = {
  //   'common': 'populate=*',
  //   'cheese-making': 'populate[0]=content.links&populate[1]=content.bg'
 
  // };

  const gtagCall = (title) => {
    if (!window.dataLayer) {
        window.dataLayer = [];
    }
    window.dataLayer.push({
        event: 'pageview',
        page: window.location.pathname,  // Page path
        title: title,  // Page title
    });
};

  const fetchPageData = async (lang) => {
    try {
      const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/api/pages/${slug}?populate[0]=content.links&populate[1]=content.bg&populate[2]=content.list.image&populate[3]=content.videoUrl&populate[4]=content.poster&locale=${lang}`);
      if (!res.ok) throw new Error("Failed to fetch page data");
      const data = await res.json();      
      setPageData(data.data);
      // gtagCall(pageData.attributes?.title || "Dynamic Page");
    } catch (error) {
      console.error("Error fetching page data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    
    if (pageData && pageData?.title) {
      gtagCall(pageData.title);  // Call gtag after pageData is updated
    }
  }, [pageData]);  // Only trigger when pageData changes
  

  useEffect(() => {
    const lang = secureLocalStorage.getItem("lang");
    
    if (!slug) return; // Wait until the `slug` is defined

    fetchPageData(lang);
  }, [slug]);

  if (loading) {
    return (
      <div className="pageLoader">
        <img src="/loader.png" alt="loader" />
      </div>
    );
  }

  if (!pageData) {
    return <div>Page not found</div>;
  }

  return (
    <>
      <Head>
        <title>{pageData?.title || "Dynamic Page"}</title>
      </Head>
      <Link 
          href={pageData?.backUrl || "/"} 
          className="backBtn"
      >
          <Back />
      </Link>
      {/* Render the content based on the components */}
      {pageData?.content?.map((component, index) => {
        switch (component.__component) {
          case "zones.page-landing":
            return <PageLanding key={index} data={component} />;
          case "zones.image-content":
            return <ImageContent key={index} data={component} />;
          case "zones.accordion-content":
            return <ContentAccordion key={index} data={component} />;
          case "zones.title-content":
            return <TitleContent key={index} data={component} />;
          case "zones.video-content":
            return <VideoContent key={index} data={component} />;
          case "zones.gallery-content":
            return <GalleryContent key={index} data={component} />;
          default:
            return null;
        }
      })}
    </>
  );
}
