import styles from './styles.module.scss';

export default function TitleContent({ data }) {

    return (
        <div className={styles.titleContent} style={{ '--target-color': data?.theme || '#F3D682' }}>
            <div className={`container ${styles.wrap}`}>
                <h1>{data?.title}</h1>
                {data?.subTitle && <div className={styles.subTitle}>
                    {data?.subTitle}
                </div>}

                <div className={styles.cnt}>
                    {data?.list?.map((cnt, index) => (
                        <div key={index}>
                            <h3  dangerouslySetInnerHTML={{ __html: cnt.title, }}></h3>
                            <div className={styles.contnet} dangerouslySetInnerHTML={{ __html: cnt.description, }} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

