import styles from './styles.module.scss';
import Link from 'next/link';
import BtnCircleArrow from '../../public/icons/btn-circle-arrow.svg';
import { useEffect, useState } from 'react';

export default function PageLanding({ data }) {
    const [pageLoader, setPageLoader] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setPageLoader(true);
        }, 100);
    }, []);

    return (
        <div className={`${styles.pageLanding} ${pageLoader ? styles.pageReady : ''}`} style={{ '--target-color': data?.theme || '#F3D682' }}>
            {data?.bg && <figure className={styles.bg}>
                <img src={data?.bg.url} alt='' />
            </figure>}
            <div className={`container ${styles.wrap}`}>
                {data?.title && <h1 className='title'><span><span>{data?.title}</span></span></h1>}
                {data?.ellipseTitle && <div className={styles.ellipseTitle}>
                    {data?.ellipseTitle}
                </div>}
                {data?.subTitle && <h2 dangerouslySetInnerHTML={{ __html: data?.subTitle, }} />}
                {data?.content && <div className={styles.contnet}>
                    <div dangerouslySetInnerHTML={{ __html: data?.content, }} />
                </div>}
                {data?.links && <nav>
                    {data?.links?.map((link, index) => {
                        // Check if the URL starts with "http://" or "https://" to determine if it's an external URL
                        const isExternal = /^https?:\/\//.test(link.url);

                        return isExternal ? (
                            <a key={index} href={link.url} target="_blank" rel="noopener noreferrer">
                                <span>{link.text}</span>
                                <BtnCircleArrow />
                            </a>
                        ) : (
                            <Link key={index} href={`/${link.url.replace(/^\/+/, '')}`}>
                                <span>{link.text}</span>
                                <BtnCircleArrow />
                            </Link>
                        );
                    })}
                </nav>}
            </div>
        </div>
    );
}

