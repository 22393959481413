
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import styles from './styles.module.scss';
import Link from 'next/link';

export default function ImageContent({ data }) {

    return (
        <div className={styles.imageContent} style={{ '--target-color': data?.theme || '#F3D682' }}>
            <div className={`container ${styles.wrap}`}>
                <h1>{data?.title}</h1>
                {data?.subTitle && <div className={styles.subTitle}>
                    {data?.subTitle}
                </div>}
                {data.contentSlider ? <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={0}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className={styles.slider}
                >

                    {data?.list?.map((cnt, index) => (
                        <SwiperSlide key={index} className={styles.cnt}>
                            <div>
                                <div className={styles.content}>
                                    <div dangerouslySetInnerHTML={{ __html: cnt.description, }} />
                                </div>
                                <figure>
                                    <img src={cnt?.image?.url} alt={cnt?.image?.alternativeText} />
                                </figure>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper> : <div className={styles.lists}>
                    {data?.list?.map((cnt, index) => (
                        <div key={index} className={styles.cnt}>
                            {cnt.description && <div className={styles.content}>
                                <div dangerouslySetInnerHTML={{ __html: cnt.description, }} />
                            </div>}
                            {cnt.image && <figure>
                                <img src={cnt.image.url} alt={cnt.image.alternativeText} />
                            </figure>}
                            {cnt.ctaURL && <div className={styles.action}><Link href={`${cnt.ctaURL}`} className={styles.cta}>{cnt.ctaText}</Link></div>}
                        </div>
                    ))}
                </div>}
            </div>
        </div>
    );
}

