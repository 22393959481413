import React, { useState, useEffect } from "react";
import styles from './styles.module.scss';
import BtnCircleArrowDown from '../../public/icons/icon-circle-arrow-down.svg';

export default function ContentAccordion({ data }) {

    const [activeIndex, setActiveIndex] = useState(null); // Track the active accordion index

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index); // Toggle the active accordion
    };
    
    return (
        <div className={styles.contentAccordion} style={{ '--target-color': data?.theme || '#F3D682' }}>
            <div className={`container ${styles.wrap}`}>
                <h1>{data?.title}</h1>
                {data?.subTitle && <div className={styles.subTitle}>
                    {data?.subTitle}
                </div>}
                <div className={styles.lists}>
                    {data?.list?.map((cnt, index) => (
                        <div key={index} className={`${styles.cnt} ${activeIndex === index ? styles.active : ''}`} style={{ '--target-color': cnt?.color || '#F3D682' }}>
                            <div>
                                <button onClick={() => toggleAccordion(index)}><span>{cnt.title}</span><BtnCircleArrowDown /></button>
                                {activeIndex === index && (<div className={styles.content}>
                                    <div dangerouslySetInnerHTML={{ __html: cnt.description,}}/>
                                </div>)}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

